import React, { useState } from "react";
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import PageHeader from "../../components/PageHeader"
import { useHistory } from "react-router"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getDateOneFormatToAnother } from "../../utils/DateUtils"
import { useSelector, useDispatch } from "react-redux";
import { createProjectSchedule } from "../../actions/projectScheduleAction";
import Message from "../../utils/Message";
import { userId } from "../../utils/userUtils";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useFetch } from "../../customHooks/fetchData"
import { getProjectProfileListURL, createProjectScheduleURL } from "../../utils/allurls"

export default function CreateProjectSchedule() {

  const history = useHistory();
  const dispatch = useDispatch()
  const projectScheduleReducer = useSelector(state => state.projectScheduleReducer)
  const [projectId, setProjectId] = useState()

  const initProjectSchedule = {
    tenant_id: userId(),
    project_id: "",
    schedule_name: "",
    schedule_details: "",
    start_date: "",
    finish_date: "",
    approval_status: "pending",
    schedule_status: "pending"
    
  }

  const { loading, data, success } = useFetch(getProjectProfileListURL("approved", userId()))

  const [projectProfileData, setProjectProfileData] = useState(initProjectSchedule)
  const [project_start_date_picker, set_project_start_date_picker] = useState(new Date());
  const [project_completion_date_picker, set_project_completion_date_picker] = useState(new Date());

  const handleOnchange = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value
    setProjectProfileData({
      ...projectProfileData,
      [fieldName]: fieldValue
    })

    console.log("projectProfileData : ", projectProfileData)
  }

  const projectIdChange = (event) => {
    setProjectId(event.target.value);
  
    setProjectProfileData({
      ...projectProfileData,
      project_id: event.target.value
    })
  };

  const resetPageData = () => {
    //setProjectProfileData(initProjectProfile)
  }


  const submitData = (e) => {
    e.preventDefault();
    dispatch(createProjectSchedule(projectProfileData))

  }

  return (
    <div> {
      (<div className="body">
        <PageHeader
          HeaderText={`Create project schedule`}
          Breadcrumb={[
            { name: "Profile schedule", navigate: "" },
            { name: "Create", navigate: "" },
          ]}
        />
        <h6></h6>
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12">
            <div className="form-group ">


            <span>Select project</span>
            
              <Select
                className="form-control"
                labelId="demo-select-small form-group"
                id="demo-select-small"
                value={projectId}
                label="Select project"
                onChange={projectIdChange}
              >
                <MenuItem className="form-group" value="Select project">
                  <em>Select project</em>
                </MenuItem>

                {success && data.data.map((item, index)=> {
                  return (<MenuItem value={item.id}>{item.project_name}</MenuItem>)
                })}
              </Select>
            </div>
            <div className="form-group">
              <span>Schedule name</span>
              <input
                className="form-control"
                placeholder=""
                name="schedule_name"
                value={projectProfileData.schedule_name}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>
            <div className="form-group">
              <span>Schedule details</span>
              <input
                className="form-control"
                placeholder=""
                name="schedule_details"
                value={projectProfileData.schedule_details}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>
           
           
            <div className="form-group">
              <span>Project schedule start date</span>
              <DatePicker dateFormat="yyyy-MM-dd" name="start_date" className="form-control" selected={project_start_date_picker}
                onChange={(date) => {
                  set_project_start_date_picker(date)
                  setProjectProfileData((state) => {
                    return {
                      ...state,
                      start_date: getDateOneFormatToAnother(date, "yyyy-MM-DD")
                    }
                  })
                }
                }
              />
            </div>
            <div className="form-group">
              <span>Project schedule completion date</span>
              <DatePicker dateFormat="yyyy-MM-dd" name="finish_date" className="form-control" selected={project_completion_date_picker}
                onChange={(date) => {
                  set_project_completion_date_picker(date)
                  setProjectProfileData((state) => {
                    return {
                      ...state,
                      finish_date: getDateOneFormatToAnother(date, "yyyy-MM-DD")
                    }
                  })
                }
                }
              />
            </div>
            
           
            <div className="form-group">

              {(projectScheduleReducer.showMessage && !projectScheduleReducer.isLoading) && <Message data={projectScheduleReducer.updatedMessageDialog} />}

              {initProjectSchedule && resetPageData()}
            </div>


            <div>

              <Button onClick={(e) => { submitData(e) }} variant="primary">
                {
                  projectScheduleReducer.loading && <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                }

                {projectScheduleReducer.loading ? "Submitting..." : "Create new schedule"}
              </Button>

              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

              <Button onClick={(e) => { history.push("project-schedule-dashboard") }} variant="secondary">

                {"Back to project schedule list"}
              </Button>
              <br></br>
              <br></br>
              <br></br>

            </div>

          </div>


        </div>
      </div>)}


    </div>
  )
}
