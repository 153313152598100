import React, { useEffect, useState } from 'react';
import { TENANT_USER, ADMIN_USER } from '../../utils/constants';
import { useFetch } from '../../customHooks/fetchData';
import { getDashboardDataURL } from '../../utils/allurls';
import { getTenantDashboardDataList, getDashboardPiechartData } from '../../utils/GeneralUtils';
import SparkleCard from '../../components/SparkleCard';
import axios from 'axios';
import { BASE_HEADERS, BASE_URL } from '../../utils/baseValues';
import './ProjectImplementationProgressReport.css';

const url = `${BASE_URL}/dashboard-api/all?get=running-project-details,operational-project-details`;

export default function ProjectImplementationProgressReport(props) {
  const [dashboardData, updateDashboardData] = useState({});

  const getData = async () => {
    const headers = BASE_HEADERS();
    const resp = await axios.get(url, { headers });
    if (resp === 200 || true) {
      updateDashboardData(resp.data.data);
    }
  };

  console.log(dashboardData);

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className='w-100'>
      <div className='mb-5 w-100'>
        <h6 className='mb-2'>Running Project</h6>
        <RunningProject project={dashboardData?.running_project_details || {}} />
      </div>
      {dashboardData?.operational_project_details?.length > 0 && (
        <div className='w-100'>
          <h6 className='mb-2'>Operational Project</h6>
          <OperationalProject projects={dashboardData.operational_project_details} />
        </div>
      )}
    </div>
  );
}

export function RunningProject({ project }) {
  return (
    <div className='running-project justify-content-start justify-content-lg-between '>
      <CartItem label='Total Running Project' value={project?.running_project_number || 0} />
      <CartItem label='Total Expense (in Lac)' value={project?.all_running_total_expense || 0} />
      <CartItem label='Total Expense In Year (in Lac)' value={project?.all_running_total_till_year_expense || 0} />
      <CartItem label='Financial Progress (%)' value={project?.avg_financial_progress || 0} />
      <CartItem label='Physical Progress (%)' value={project?.avg_physical_progress || 0} />
    </div>
  );
}

export function OperationalProject({ projects = [] }) {
  return (
    <div className='p-4 operational-project justify-content-start justify-content-lg-between '>
      {projects?.map((it, i) => (
        <OpCartItem project={it} key={i} />
      ))}
    </div>
  );
}

export function CartItem({ label, value }) {
  return (
    <div className='card-item'>
      <p>{label}</p>
      <h4>{value}</h4>
    </div>
  );
}

export function OpCartItem({ project }) {
  return (
    <div>
      <div className='header'>{project.park_name}</div>
      <div className='op-card-item'>
        <div className='d-flex w-100'>
          <CartItem label='Number of Investor' value={project?.number_of_investor} />
          <CartItem label='Total Employment' value={project?.employment} />
          <CartItem label='Total Investment (Tk)' value={project?.investment} />
          <CartItem label='Due Bill (Tk)' value={project.due_bill} />
        </div>
      </div>
    </div>
  );
}

// export default function ProjectImplementationProgressReport(props) {

//     var requestURL = "project-progress"

//     if (props.user_type === TENANT_USER) {
//         requestURL = "project-progress"
//     }
//     const dashboardData = useFetch(getDashboardDataURL(requestURL))

//     return (
//         <>
//             {dashboardData.success && getTenantDashboardDataList(dashboardData.data.data).map((item, i) => {
//                 return <SparkleCard
//                     index={i}
//                     key={i}
//                     Heading={item.name}
//                     Money={`${item.value}${item.valueType}`}
//                     PerText={""}
//                     isRandomUpdate={false}
//                     mainData={item.sparklineData.data}
//                     chartColor={item.sparklineData.areaStyle.color}
//                     ContainerClass="col-lg-3 col-md-6 col-sm-6"
//                 />
//             })}
//         </>
//     )
// }
