import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import Login from "./screens/Login";
import NavbarMenu from "./components/NavbarMenu";
import forgotpassword from "./screens/Dashbord/ForgotPassword";
import page404 from "./screens/Auth/Page404";
import page403 from "./screens/Auth/Page403";
import page500 from "./screens/Auth/Page500";
import page503 from "./screens/Auth/Page503";
import profilev1page from "./screens/Pages/ProfileV1";
import profilev2page from "./screens/Pages/ProfileV2";
import maintanance from "./screens/Pages/Maintanance";
import ProfileDetails from "./screens/Pages/ProfileDetails";
import ChangePasswordSettings from "./screens/Dashbord/ChangePasswordSettings";
import PrivateRoute from "./utils/PrivateRoute";
import Dashboard from "./screens/Dashbord/Dashbord";
import RentDashboard from "./screens/Dashbord/RentDashboard";
import ProjectScheduleDashboard from "./screens/Dashbord/ProjectScheduleDashboard"
import ProjectsDashboard from "./screens/Dashbord/ProjectsDashboard"
import AddTenant from "./screens/Dashbord/AddTenant"
import ContactDashboard from "./screens/Dashbord/ContactDashboard";
import TenantUserDashbord from "./screens/TenantDashboard/TenantUserDashbord";
import TenantAdminDashboard from "./screens/Dashbord/TenantAdminDashboard";
import AdminUserList from "./screens/Dashbord/AdminUserList"
import AdminNewUserCreate from "./screens/Dashbord/AdminNewUserCreate"
import AddBhtpaContact from "./screens/Dashbord/AddBhtpaContact"
import TenantUserProjectProfileDashboard from "./screens/TenantDashboard/TenantUserProjectProfileDashboard";
import TenantUserProjectScheduleDashboard from "./screens/TenantDashboard/TenantUserProjectScheduleDashboard";
import TenantUserProjectScheduleExtendDashboard from "./screens/TenantDashboard/TenantUserProjectScheduleExtendDashboard";
import TenantUserSupportDashbord from "./screens/TenantDashboard/TenantUserSupportDashbord";
import CreateProjectProfile from "./screens/TenantDashboard/CreateProjectProfile";
import CreateProjectSchedule from "./screens/TenantDashboard/CreateProjectSchedule"
import CreateProjectScheduleExtendRequest from "./screens/TenantDashboard/CreateProjectScheduleExtendRequest"
import ProjectScheduleExtendDashboard from "./screens/Dashbord/ProjectScheduleExtendDashboard";
import CreateProjectSupport from "./screens/TenantDashboard/CreateProjectSupport";
import ProjectSupportDashbord from "./screens/Dashbord/ProjectSupportDashbord";
import ChatSupportDashbord from "./screens/Dashbord/ChatSupportDashbord"
import NoticeboardDashbord from "./screens/Dashbord/NoticeboardDashbord";
import CreateNoticeboard from "./screens/TenantDashboard/CreateNoticeboard";
import TenantUserRentDashboard from "./screens/TenantDashboard/TenantUserRentDashboard";
import CreateRentInvoice from "./screens/TenantDashboard/CreateRentInvoice";
import PDUserDashboard from "./screens/pdusers/PDUserDashbord";
import ProjectImplNameDashboard from "./screens/pdusers/ProjectImplNameDashboard";
import ProjectImplDetailsDashboard from "./screens/Dashbord/ProjectImplDetailsDashboard";
import ProjectPackageDashboard from "./screens/Dashbord/ProjectPackageDashboard";
import CreateProjectPackage from "./screens/TenantDashboard/CreateProjectPackage";
import CreateOrUpdateProjectImplDetails from "./screens/TenantDashboard/CreateOrUpdateProjectImplDetails";
import CreateProjectImplName from "./screens/TenantDashboard/CreateProjectImplName";

window.__DEV__ = true;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
    };
  }
  render() {

    var res = window.location.pathname;
    var baseUrl = process.env.PUBLIC_URL;
    baseUrl = baseUrl.split("/");
    res = res.split("/");
    res = res.length > 0 ? res[baseUrl.length] : "/";
    res = res ? res : "/";
    const activeKey1 = res;
    //console.log('activeKey1: ' + activeKey1);


    return (
      <div id="wrapper">
        { activeKey1 === "" ||
          activeKey1 === "/" ||
          activeKey1 === "login" ||
          activeKey1 === "registration" ||
          activeKey1 === "forgotpassword" ||
          activeKey1 === "page404" ||
          activeKey1 === "page403" ||
          activeKey1 === "page500" ||
          activeKey1 === "page503" ||
          activeKey1 === "maintanance" ? (
          <Switch>
            {/* <Route exact path={`${process.env.PUBLIC_URL}`} component={Login} /> */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={Login}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/login`}
              component={Login}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/forgotpassword`}
              component={forgotpassword}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page404`}
              component={page404}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page403`}
              component={page403}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page500`}
              component={page500}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page503`}
              component={page503}
            />
          
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/maintanance`}
              component={maintanance}
            />
          </Switch>
        ) : (
          <Fragment>
            <NavbarMenu history={this.props.history} activeKey={activeKey1} />
            <div id="main-content">
              <Switch>

            

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/dashboard`} >
                  <Dashboard />
                </PrivateRoute>

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/dashboard-map`} >
                  <Dashboard />
                </PrivateRoute>

                

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin-rent-dashaboard`}
                >
                  <RentDashboard />
                </PrivateRoute>
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/create-rent-invoice`}
                >
                  <CreateRentInvoice />
                </PrivateRoute>
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/tenant-rent-dashaboard`}
                >
                  <TenantUserRentDashboard />
                </PrivateRoute>
               
       

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin-support-dashboard`}
                >
                  <ProjectSupportDashbord  />
                </PrivateRoute>

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin-chat-support-dashboard`}
                >
                  <ChatSupportDashbord  />
                </PrivateRoute>

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/notice-board-dashboard`}
                >
                  <NoticeboardDashbord  />
                </PrivateRoute>
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/create-notice-board`}
                >
                  <CreateNoticeboard  />
                </PrivateRoute>

                

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/project-implemention-dashboard`}
                >
                  <ProjectImplDetailsDashboard  />
                </PrivateRoute>

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/project-implementation-package-list`}
                >
                  <ProjectPackageDashboard  />
                </PrivateRoute>

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/save-project-package`}
                >
                  <CreateProjectPackage  />
                </PrivateRoute>

                

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/project-implemention-name-list`}
                >
                  <ProjectImplNameDashboard  />
                </PrivateRoute>
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/create-project-implementation-name`}
                >
                  <CreateProjectImplName  />
                </PrivateRoute>
               
                

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/contacts-person`}
                >
                  <ContactDashboard  />
                </PrivateRoute>
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/add-bhtpa-contact`}
                >
                  <AddBhtpaContact  />
                </PrivateRoute>

             
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/tenant-user-dashboard`}
                >
                  <TenantUserDashbord  />
                </PrivateRoute>

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/project-profile-dashboard`}
                >
                  <TenantUserProjectProfileDashboard  />
                </PrivateRoute>

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/project-schedule-dashboard`}
                >
                  <TenantUserProjectScheduleDashboard  />
                </PrivateRoute>

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/project-schedule-extend-dashboard`}
                >
                  <TenantUserProjectScheduleExtendDashboard  />
                </PrivateRoute>

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/support-dashboard`}
                >
                  <TenantUserSupportDashbord  />
                </PrivateRoute>

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/create-project-profile`}
                >
                  <CreateProjectProfile  />
                </PrivateRoute>

                
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/create-support`}
                >
                  <CreateProjectSupport  />
                </PrivateRoute>
                
                

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin-user-list`}
                >
                  <AdminUserList  />
                </PrivateRoute>

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin-create-user`}
                >
                  <AdminNewUserCreate  />
                </PrivateRoute>

                

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/tenant-admin-dashboard`}
                >
                  <TenantAdminDashboard />
                </PrivateRoute>
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/create-tenant`}
                >
                  <AddTenant />
                </PrivateRoute>
                
              
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/profiledetails`}>
                  <ProfileDetails />
                </PrivateRoute>
                
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/project-dashboard`}>
                  <ProjectsDashboard />
                </PrivateRoute>

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/pd-user-dashboard`}>
                  <PDUserDashboard />
                </PrivateRoute>
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/project-implemention-details-update`}>
                  <CreateOrUpdateProjectImplDetails />
                </PrivateRoute>
               

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/create-project-schedule`}>
                  <CreateProjectSchedule />
                </PrivateRoute>

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/request-project-schedule-extend`}>
                  <CreateProjectScheduleExtendRequest />
                </PrivateRoute>
                
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/operation-dashboard`}>
                  <ProjectScheduleDashboard />
                </PrivateRoute>

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin-project-schedule-extend-dashboard`}>
                  <ProjectScheduleExtendDashboard />
                </PrivateRoute>

                

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/updatepassword`}>
                  <ChangePasswordSettings />
                </PrivateRoute>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/profilev1page`}
                  component={profilev1page}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/profilev2page`}
                  component={profilev2page}
                />
                <Route render={() => <Redirect to="/" />} />
                
              </Switch>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ loginReducer }) => ({
  isLoggedin: loginReducer.isLoggedin,
});

export default withRouter(connect(mapStateToProps, {})(App));
