import React, { useState } from "react";
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import PageHeader from "../../components/PageHeader"
import { useHistory } from "react-router"
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { updateRentAttribute } from "../../actions/rentAction";
import Message from "../../utils/Message";
import { userId } from "../../utils/userUtils";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useFetch } from "../../customHooks/fetchData"
import { getProjectProfileListURL, getRentListByProjectIdURL, getAllUsersURL } from "../../utils/allurls"
import { BASE_HEADERS } from "../../utils/baseValues";
import axios from 'axios'
import { TENANT_USER } from "../../utils/constants";



export default function CreateRentInvoice() {

  const history = useHistory();
  const dispatch = useDispatch()
  const rentReducer = useSelector(state => state.rentReducer)
  const [tenantId, setTenantId] = useState()
  const [projectId, setProjectId] = useState()
  const [rentYear, setRentYear] = useState()
  const [projectProfileList, setProjectProfileList] = useState(null)
  const [initRentList, setInitRentList] = useState(null)

  const initRentData = {
    id:-1,
    tenant_id: userId(),
    project_id: projectId,
    year: -1,
    only_rent: 0,
    vat: 15,
    tax: 5,
    other: 0,
    total_amount: 0,
    invoice_created_date: "",
    payment_status: "rent_issued",
  }


  const tenantList = useFetch(getAllUsersURL(TENANT_USER))
  const [rentData, setRentData] = useState(initRentData)

  const handleOnchange = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value
    var total_amount_temp = 0


    if (fieldName === 'only_rent') {
      total_amount_temp = parseFloat(fieldValue) + ((parseFloat(fieldValue) * (rentData.vat + rentData.tax)) / 100) + rentData.other
    } else if (fieldName === 'vat') {
      total_amount_temp = rentData.only_rent + ((rentData.only_rent * (parseFloat(fieldValue) + rentData.tax)) / 100) + rentData.other
    } else if (fieldName === 'tax') {
      total_amount_temp = rentData.only_rent + ((rentData.only_rent * (rentData.vat + parseFloat(fieldValue))) / 100) + rentData.other
    } else if (fieldName === 'other') {
      total_amount_temp = rentData.only_rent + ((rentData.only_rent * (rentData.vat + rentData.tax)) / 100) + parseFloat(fieldValue)
    }


    setRentData({
      ...rentData,
      [fieldName]: parseFloat(fieldValue),
      total_amount: total_amount_temp
    })

    console.log("rentData : ", rentData)
  }

  const tenantIdChange = (event) => {
    const tenant_id = event.target.value
    setTenantId(tenant_id);

    setRentData({
      ...rentData,
      tenant_id: tenant_id
    })

    getProjectList(tenant_id)

  };

  const projectIdChange = (event) => {
    const projec_id = event.target.value
    setProjectId(projec_id);

    setRentData({
      ...rentData,
      project_id: projec_id
    })

    getRentList(projec_id)

  };

  const getProjectList = async (tentID) => {

    try {

      const headers = BASE_HEADERS()
      const response = await axios.get(getProjectProfileListURL("", tentID), { headers });

      if (response.status === 200) {
        setProjectProfileList(response.data.data)

      } else {
        setProjectProfileList(null)
      }

    } catch (error) {
      setProjectProfileList(null)
    }

  }


  const getRentList = async (projec_id) => {

    try {

      const headers = BASE_HEADERS()
      const response = await axios.get(getRentListByProjectIdURL(projec_id), { headers });

      if (response.status === 200) {

        setInitRentList(response.data.data)

      } else {
        setInitRentList(null)
      }

    } catch (error) {
      setInitRentList(null)
    }

  }

  const rentYearChange = (event) => {

    const yearObject = event.target.value

    setRentYear(yearObject.year);

    console.log("event.target.value:",event.target.value)
    console.log("event.target.name:",event.target.name)

    setRentData({
      ...rentData,
      year: yearObject.year,
      id: yearObject.id
    })

  };



  const resetPageData = () => {
    //setRentData(initProjectScheduleExtend)
  }


  const submitData = (e) => {
    e.preventDefault();

    const invoice_created_date   = (new Date().getTime()/1000);

    dispatch(updateRentAttribute({...rentData, invoice_created_date:invoice_created_date}))

  }

  return (
    <div> {
      (<div className="body">
        <PageHeader
          HeaderText={`Create invoice`}
          Breadcrumb={[
            { name: "rent", navigate: "" },
            { name: "create-invoice", navigate: "" },
          ]}
        />
        <h6></h6>
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12">

            <div className="form-group ">

              <span>Select investor account</span>
              {/* <InputLabel id="demo-select-small form-group">Select project</InputLabel> */}
              <Select
                className="form-control"
                labelId="demo-select-small form-group"
                id="demo-select-small"
                value={tenantId}
                label="Select tenant"
                onChange={tenantIdChange}
              >
                <MenuItem className="form-group" value="Select investor">
                  <em>Select investor</em>
                </MenuItem>

                {tenantList && (tenantList.success && tenantList.data.data.map((item, index) => {
                  return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                }))}
              </Select>
            </div>

            <div className="form-group ">


              <span>Select project</span>
              {/* <InputLabel id="demo-select-small form-group">Select project</InputLabel> */}
              <Select
                className="form-control"
                labelId="demo-select-small form-group"
                id="demo-select-small"
                value={projectId}
                label="Select project"
                onChange={projectIdChange}
              >
                <MenuItem className="form-group" value="Select project">
                  <em>Select project</em>
                </MenuItem>

                {projectProfileList && projectProfileList.map((item, index) => {
                  return (<MenuItem value={item.id}>{item.project_name}</MenuItem>)
                })}
              </Select>
            </div>
            <div className="form-group ">

              <span>Select year of rent</span>
              {/* <InputLabel id="demo-select-small form-group">Select project</InputLabel> */}
              <Select
                className="form-control"
                labelId="demo-select-small form-group"
                id="demo-select-small"
                value={rentYear}
                label="Select year of rent"
                onChange={rentYearChange}
              >
                <MenuItem className="form-group" value="Select project">
                  <em>Select year of rent</em>
                </MenuItem>

                {initRentList && initRentList.map((item, index) => {
                  return (<MenuItem value={item}>{item.year}</MenuItem>)
                })}
              </Select>
            </div>
            <div className="form-group">
              <span>Only rent amount(Tk)</span>
              <input
                className="form-control"
                placeholder=""
                name="only_rent"
                value={rentData.only_rent}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>
            <div className="form-group">
              <span>VAT(%)</span>
              <input
                className="form-control"
                placeholder=""
                name="vat"
                value={rentData.vat}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>

            <div className="form-group">
              <span>TAX(%)</span>
              <input
                className="form-control"
                placeholder=""
                name="tax"
                value={rentData.tax}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>


            <div className="form-group">
              <span>Other(Tk)</span>
              <input
                className="form-control"
                placeholder=""
                name="other"
                value={rentData.other}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>

            <div className="form-group">
              <span>Total rent amount(Tk)</span>
              <input
                className="form-control"
                placeholder=""
                name="total_amount"
                value={rentData.total_amount}
                type="text"
                readOnly
              />
            </div>

            <div className="form-group">

              {(rentReducer.showMessage && !rentReducer.isLoading) && <Message data={rentReducer.updatedMessageDialog} />}

              {initRentData && resetPageData()}
            </div>


            <div>

              <Button onClick={(e) => { submitData(e) }} variant="primary">
                {
                  rentReducer.loading && <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                }

                {rentReducer.loading ? "Submitting..." : "Create invoice"}
              </Button>

              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

              <Button onClick={(e) => { history.push("support-dashboard") }} variant="secondary">

                {"Back to support dashboard"}
              </Button>
              <br></br>
              <br></br>
              <br></br>

            </div>

          </div>


        </div>
      </div>)}


    </div>
  )
}
