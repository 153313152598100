import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { TENANT_USER, ADMIN_USER } from '../../utils/constants';
import Button from 'react-bootstrap/Button';
import { useFetch, useFetchDataFrom } from '../../customHooks/fetchData';
import { getProjectScheduleListURL, scheduleCompletionApiEndpoint } from '../../utils/allurls';
import { userId } from '../../utils/userUtils';
import { useSelector, useDispatch } from 'react-redux';
import { getProjectUpdateJsonBody } from '../../utils/GeneralUtils';
import { updateProjectScheduleStatus, deleteProjectSchedule } from '../../actions/projectScheduleAction';
import swal from 'sweetalert';
import { PaginatedItems } from '../../components/Paginate/Paginate';
import { Modal } from 'react-bootstrap';
import axios from 'axios';

export default function ProjectScheduleList(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const projectScheduleReducer = useSelector((state) => state.projectScheduleReducer);
  const [show, setShow] = useState(false);
  const [activeData, setActiveData] = useState(null);
  const [formData, updateFormData] = useState({});

  const getUrl = (user_type, query) => {
    var requestURL;
    if (user_type === TENANT_USER) {
      requestURL = getProjectScheduleListURL(userId(), '', '', '', query.page, query.per_page);
    } else if (user_type === ADMIN_USER) {
      requestURL = getProjectScheduleListURL('', '', '', '', query.page, query.per_page);
    }
    return requestURL;
  };

  const [queryObj, updateQueryObject] = useState({ page: 0, per_page: 20 });
  //   const { loading, data} = useFetch(getAllUsersURL(user_type));
  // const { loading, data, success } = useFetch(requestURL);

  const { loading, data, success, disPatcher } = useFetchDataFrom(getUrl(props.user_type, queryObj));

  const handleClose = () => {
    setShow(false);
    setActiveData(null);
  };

  const handleShow = (preData) => {
    setActiveData(preData);
    setShow(true);
  };

  const handleUpdate = () => {
    const res = handleClickOnUpdate({ id: formData.id, complete_percentage: formData.complete_percentage || '' });
    if (res) {
      handleClose();
      updateFormData({});
      window.location.reload();
    } else alert('something is wrong');
  };

  const handleOnchange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    updateFormData({
      ...formData,
      [fieldName]: fieldValue,
    });
  };

  useEffect(() => {
    if (activeData) {
      updateFormData(activeData);
    }
  }, [activeData]);

  useEffect(() => {
    disPatcher(getUrl(props.user_type, queryObj));
  }, [queryObj, props.user_type, disPatcher]);

  console.log('Project list', data);

  const getProjectStatus = (status) => {
    var statusView;

    if (status === 'completed' || status === 'schedule completed') {
      statusView = <span className='badge badge-success bg-success text-white'>{status}</span>;
    } else if (status === 'approved') {
      statusView = <span className='badge badge-danger bg-info text-white'>{status}</span>;
    } else if (status === 'pending') {
      statusView = <span className='badge badge-danger bg-warning text-white'>{status}</span>;
    } else {
      statusView = <span className='badge badge-danger bg-warning text-white'>{status}</span>;
    }

    return statusView;
  };

  const deleteSchedule = (projectId) => {
    dispatch(deleteProjectSchedule(projectId));
  };

  const projectUpdateSuccess = (success) => {
    if (success) {
      swal('Project schedule update status', 'Updated succesfully', 'success').then(function () {
        window.location.reload(false);
      });
    }
  };

  const projectDeleteSuccess = (success) => {
    if (success) {
      swal('Project schedule delete status', 'Project schedule deleted succesfully', 'success').then(function () {
        window.location.reload(false);
      });
    }
  };

  return (
    <>
      <div className='col-lg-12'>
        <div className='card product_item_list product-order-list'>
          <div className='header align-space-between-side'>
            <h2>Project schedule list</h2>
            {props.user_type === TENANT_USER && (
              <Button
                onClick={(e) => {
                  history.push('create-project-schedule');
                }}
                variant='primary'
              >
                {'Create project schedule'}
              </Button>
            )}
          </div>
          <div className='body'>
            <div className='table-responsive'>
              <table className='table table-hover m-b-0'>
                <thead className='thead-dark'>
                  <tr>
                    <th data-breakpoints='sm xs'>Schedule Name</th>
                    <th>Schedule details</th>
                    <th>Investor name</th>

                    <th data-breakpoints='xs'>Date</th>
                    <th data-breakpoints='xs'>
                      Completion
                      <h6 style={{ textAlign: 'center' }}>(%)</h6>
                    </th>

                    <th data-breakpoints='sm xs md'>Status</th>
                    <th data-breakpoints='xs md'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading && 'Loading...'}
                  {success &&
                    data.data.map((project, index) => (
                      <tr key={index}>
                        <td>
                          <div className='request-top' style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                            <p>{project.schedule_name}</p>
                          </div>
                        </td>
                        <td>
                          <div className='request-top' style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                            <p>{project.schedule_details}</p>
                          </div>
                        </td>
                        <td>{project.project_name}</td>

                        <td>
                          {project.start_date && 'Start date: ' + project.start_date}
                          <br></br>
                          {project.finish_date && 'Finish date: ' + project.finish_date}
                          <br></br>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div
                              style={{
                                width: '40px',
                                height: '30px',
                                border: '1px solid #80808057',
                                marginRight: '4px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {project.complete_percentage || '0'}
                            </div>
                            <Button
                              style={{ height: '30px', width: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                              onClick={() => handleShow(project)}
                            >
                              Edit
                            </Button>
                          </div>
                        </td>

                        <td>{getProjectStatus(project.schedule_status)}</td>
                        {props.user_type === TENANT_USER && (
                          <td>
                            {/* <a className="btn btn-outline-secondary mr-1">
                                           <i className="icon-pencil"></i>
                                       </a> */}
                            {project.schedule_status === 'pending' && (
                              <a className='btn btn-outline-secondary'>
                                <i
                                  className='icon-trash'
                                  onClick={() => {
                                    deleteSchedule(project.id);
                                  }}
                                ></i>
                              </a>
                            )}

                            {project.schedule_status === 'approved' && (
                              <Button
                                onClick={() => {
                                  dispatch(updateProjectScheduleStatus(getProjectUpdateJsonBody(project.id, 'completed')));
                                }}
                              >
                                {'Complete'}
                              </Button>
                            )}
                          </td>
                        )}

                        {props.user_type === ADMIN_USER && (
                          <td>
                            {project.schedule_status === 'pending' && (
                              <Button
                                onClick={() => {
                                  dispatch(updateProjectScheduleStatus(getProjectUpdateJsonBody(project.id, 'approved')));
                                }}
                              >
                                {'Approve'}
                              </Button>
                            )}

                            {project.schedule_status === 'completed' && (
                              <Button
                                onClick={() => {
                                  dispatch(updateProjectScheduleStatus(getProjectUpdateJsonBody(project.id, 'schedule completed')));
                                }}
                              >
                                {'Complete Approve'}
                              </Button>
                            )}

                            <a className='btn btn-outline-secondary'>
                              <i
                                className='icon-trash'
                                onClick={() => {
                                  deleteSchedule(project.id);
                                }}
                              ></i>
                            </a>
                          </td>
                        )}

                        {projectUpdateSuccess(projectScheduleReducer.updateSuccess)}
                        {projectDeleteSuccess(projectScheduleReducer.deleteSuccess)}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='my-4'>
          <PaginatedItems
            itemsPerPage={queryObj.per_page}
            onClick={(parm) => updateQueryObject({ ...queryObj, page: parm })}
            totalItems={data.pagination?.total}
          />
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className='body'>
            <div className='row px-3'>
              <div className='form-group  col-md-6'>
                <span>Enter completion (%)</span>
                <input
                  className='form-control'
                  placeholder=''
                  name='complete_percentage'
                  value={formData?.complete_percentage}
                  type='text'
                  onChange={(e) => handleOnchange(e)}
                />
              </div>
            </div>
            <Button className='btn btn-danger' onClick={handleUpdate}>
              Update
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export async function handleClickOnUpdate(postData) {
  const reqUrl = scheduleCompletionApiEndpoint;
  const res = await axios.post(reqUrl, postData);
  console.log(res);
  if (res.status === 200 || true) {
    return true;
  }
  return false;
}
