import React from 'react';
import { TENANT_USER, ADMIN_USER } from '../../utils/constants';
import { useFetch } from '../../customHooks/fetchData';
import { getDashboardDataURL } from '../../utils/allurls';
import { getExtensionStatsFormattedData } from '../../utils/GeneralUtils';
import SparkleCard from '../../components/SparkleCard';

export default function ExtensionStatsSummary(props) {
  //extention-admin-dashboard,extention-tenant-dashboard

  var requestURL = 'extention-tenant-dashboard';

  if (props.user_type === TENANT_USER) {
    requestURL = 'extention-tenant-dashboard';
  } else if (props.user_type === ADMIN_USER) {
    requestURL = 'extention-admin-dashboard';
  }
  const dashboardData = useFetch(getDashboardDataURL(requestURL));

  return (
    <>
      {dashboardData.success &&
        getExtensionStatsFormattedData(dashboardData.data.data).map((item, i) => {
          return (
            <SparkleCard
              index={i + 400}
              key={i + 400}
              Heading={item.name}
              Money={`${item.value}${item.valueType}`}
              PerText={''}
              isRandomUpdate={false}
              mainData={item.sparklineData.data}
              chartColor={item.sparklineData.areaStyle.color}
              ContainerClass='col-lg-3 col-md-6 col-sm-6'
            />
          );
        })}
    </>
  );
}
