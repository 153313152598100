import React from "react";
import PageHeader from "../../components/PageHeader";
import SparkleCard from "../../components/SparkleCard";
import { useFetch } from "../../customHooks/fetchData"
import { getDashboardDataURL } from "../../utils/allurls"
import { getTenantDashboardDataList } from "../../utils/GeneralUtils"
import InvestorProfileList from "../Dashbord/InvestorProfileList"
import {TENANT_USER} from "../../utils/constants"

export default function TenantUserProjectProfileDashboard() {


  const dashboardData = useFetch(getDashboardDataURL("project-profile-tenant"))

  return (
    <div className="dashboard-container">
      <PageHeader
        HeaderText="Dashboard"
        Breadcrumb={[{ name: "Dashboard" }]}
        
        
      />
      <div className={`dashboard-container-summary`}>

        { dashboardData.success && getTenantDashboardDataList(dashboardData.data.data).map((item, i) => {
          return <SparkleCard
            index={i}
            key={i}
            Heading={item.name}
            Money={`${item.value}${item.valueType}`}
            PerText={""}
            isRandomUpdate={false}
            mainData={item.sparklineData.data}
            chartColor={item.sparklineData.areaStyle.color}
            ContainerClass="col-lg-3 col-md-6 col-sm-6"
          />
        })}
       
      </div>
      <div className={`dashboard-container-summary`}>
      <InvestorProfileList user_type={TENANT_USER} />
      </div>
    </div>
  )
}


