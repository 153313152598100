import { BASE_URL } from './baseValues';
import { TENANT_USER } from './constants';

//Auth
export const getLoginURL: string = `${BASE_URL}/auth/user/login`;
export const getRefreshTokenURL: string = `${BASE_URL}/api/auth/token`;
export const forgotpasswordURL: string = `${BASE_URL}/api/noauth/resetPasswordByEmail`;

//user
export const getMyUserDetailsURL: string = `${BASE_URL}/myinfo`;
export const getChangePasswordURL: string = BASE_URL + '/user/change-password';
export const getUserLogoutURL: string = BASE_URL + '/api/auth/logout';
export const myProfileUpdateURL: string = BASE_URL + '/user/profile-update';
export const createNewUserURL: string = BASE_URL + '/admin/user/signup';
export const getAllUsersURL = (type: string, page: number, per_page: number): string =>
  `${BASE_URL}/users?type=${type}&page=${page || 0}&per_page=${per_page || 20}`;
export const getAllBhtpaContractPersonURL: string = `${BASE_URL}/bhtpa-contact/all`;
export const createContractPersonURL: string = `${BASE_URL}/bhtpa-contact/create`;

export const getDashboardDataURL = (options: string): string => `${BASE_URL}/dashboard-api/all?get=${options}`;

//project profile
export const createProjectProfileURL: string = `${BASE_URL}/project-profile/create`;
export const getProjectProfileListURL = (type: string = '', tenant_id: string = '', page: number, per_page: number): string =>
  `${BASE_URL}/project-profiles/all?type=${type}&tenant_id=${tenant_id}&page=${page}&per_page=${per_page}`;
export const updateProjectProfileStatusURL: string = `${BASE_URL}/project-profile/update`;
export const deleteProjectProfileURL = (projectid: string): string => `${BASE_URL}/project-profile/delete/${projectid}`;

//project schedule
export const createProjectScheduleURL: string = `${BASE_URL}/project-schedule/create`;
export const updateProjectScheduleStatusURL: string = `${BASE_URL}/project-schedule/update`;
export const getProjectScheduleListURL = (
  tenant_id: string = '',
  approval_status: string = '',
  schedule_status: string = '',
  project_id: string = '',
  page: number,
  per_page: number
): string =>
  `${BASE_URL}/project-schedule/all?tenant_id=${tenant_id}&approval_status=${approval_status}&schedule_status=${schedule_status}&project_id=${project_id}&page=${page}&per_page=${per_page}`;
export const deleteProjectScheduleURL = (scheduleid: string): string => `${BASE_URL}/project-schedule/delete/${scheduleid}`;

//project schedule extend request
export const createProjectScheduleExtendRequestURL: string = `${BASE_URL}/project-schedule-extend/create`;
export const updateProjectScheduleExtendRequestStatusURL: string = `${BASE_URL}/project-schedule-extend/update`;
export const getProjectScheduleExtendRequestListURL = (tenant_id: string = '', approval_status: string = ''): string =>
  `${BASE_URL}/project-schedule-extend/all?tenant_id=${tenant_id}&approval_status=${approval_status}`;
export const deleteProjectScheduleExtendRequestURL = (extendRequestId: string): string =>
  `${BASE_URL}/project-schedule-extend/delete/${extendRequestId}`;

//project support
export const createProjectSupportURL: string = `${BASE_URL}/project-support/create`;
export const updateProjectSupportURL: string = `${BASE_URL}/project-support/update`;
export const getProjectSupportListURL = (tenant_id: string = '', status: string = '') =>
  `${BASE_URL}/project-support/all?tenant_id=${tenant_id}&status=${status}`;
export const deleteProjectSupportURL = (projectSupportId: string): string => `${BASE_URL}/project-support/delete/${projectSupportId}`;

//project support message
export const createProjectSupportMessageURL: string = `${BASE_URL}/project-supoort-message/create`;
export const getProjectSupportMessageListURL = (project_support_id: string, status: string): string =>
  `${BASE_URL}/project-support-message/all?project_support_id=${project_support_id}&status=${status}`;

//noticeboard
export const createNoticeboardMessageURL: string = `${BASE_URL}/notice-board/create`;
export const getNoticeboardListURL = (receiver_id: string = '') => `${BASE_URL}/notice-board/all?receiver_id=${receiver_id}`;
export const deleteNoticeboardURL = (noticeboardId: string): string => `${BASE_URL}/notice-board/delete/${noticeboardId}`;

//rent
export const createRentURL: string = `${BASE_URL}/rent/create`;
export const getRentListURL = (tenant_id: string = '', status: string = ''): string => `${BASE_URL}/rent/all?tenant_id=${tenant_id}&status=${status}`;
export const getRentListByProjectIdURL = (project_id: string = ''): string => `${BASE_URL}/rent/init?project_id=${project_id}`;
export const deleteRentURL = (rentId: string): string => `${BASE_URL}/rent/delete/${rentId}`;
export const updateRentURL: string = `${BASE_URL}/rent/update`;

// project implementation progress
export const getProjectImplementationProgressListURL = (user_id: string, status: string, page: number, per_page: number): string => {
  if (user_id !== '' && status !== '') {
    return `${BASE_URL}/project-implementation-progress/all?user_id=${user_id}&status=${status}&page=${page}&per_page=${per_page}`;
  } else if (user_id !== '') {
    return `${BASE_URL}/project-implementation-progress/all?user_id=${user_id}&page=${page}&per_page=${per_page}`;
  } else if (status !== '') {
    return `${BASE_URL}/project-implementation-progress/all?status=${status}&page=${page}&per_page=${per_page}`;
  }
  return `${BASE_URL}/project-implementation-progress/all?user_id=${user_id}&status=${status}&page=${page}&per_page=${per_page}`;
};
export const createProjectImplProgressURL: string = `${BASE_URL}/project-implementation-progress/create`;
export const updateProjectImplProgressURL: string = `${BASE_URL}/project-implementation-progress/update`;
export const updateProjectTypeURL: string = `${BASE_URL}/project-implementation-progress/update/type`;
export const deleteProjectImplProgressURL = (id: string): string => `${BASE_URL}/project-implementation-progress/delete/${id}`;

export const getProjectImplementationDetailsListURL = (user_id: string, status: string): string => {
  if (user_id !== '' && status !== '') {
    return `${BASE_URL}/project-implementation-details/all?user_id=${user_id}&status=${status}`;
  } else if (user_id !== '') {
    return `${BASE_URL}/project-implementation-details/all?user_id=${user_id}`;
  } else if (status !== '') {
    return `${BASE_URL}/project-implementation-details/all?status=${status}`;
  }
  return `${BASE_URL}/project-implementation-details/all?user_id=${user_id}&status=${status}`;
};

export const getPackageListByProjectIdURL = (project_id: string, status: string): string => {
  if (project_id !== '' && status !== '') {
    return `${BASE_URL}/project-package/all?project_id=${project_id}&status=${status}`;
  } else if (project_id !== '') {
    return `${BASE_URL}/project-package/all?project_id=${project_id}`;
  } else if (status !== '') {
    return `${BASE_URL}/project-package/all?status=${status}`;
  }
  return `${BASE_URL}/project-package/all?project_id=${project_id}&status=${status}`;
};

export const createOrUpdateProjectPackageURL: string = `${BASE_URL}/project-package/create`;
export const deleteProjectPackageURL = (id: string): string => `${BASE_URL}/project-package/delete/${id}`;

export const createOrUpdateProjectImplementationDetailsURL: string = `${BASE_URL}/project-implementation-details/create`;
export const deleteProjectImplementationDetailsURL = (id: string): string => `${BASE_URL}/project-implementation-details/delete/${id}`;

export const projectImplementationUpdateShowDashboardURL: string = `${BASE_URL}/project-implementation-progress/update/show-dashboard`;
export const projectProfileUpdate: string = `${BASE_URL}/project-profile/update`;
export const userDeleteApiEndpoint = (id: any) => `${BASE_URL}/auth/user/delete/${id}`;
export const scheduleCompletionApiEndpoint = `${BASE_URL}/project-schedule/update`;
export const hiTechParkListApiEndpoint = `${BASE_URL}/project-implementation-progress/all?type=2`;
export const userListApiEndpoint = `${BASE_URL}/users?page=0&per_page=10000&type=${TENANT_USER}`;
