import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import PageHeader from '../../components/PageHeader';
import { useHistory } from 'react-router';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getDateOneFormatToAnother } from '../../utils/DateUtils';
import { useSelector, useDispatch } from 'react-redux';
import { createProjectProfile } from '../../actions/projectProfileAction';
import Message from '../../utils/Message';
import { userId, userType } from '../../utils/userUtils';
import SelectFromOption from '../../components/SelectedInput/SelectedInput';
import axios from 'axios';
import { hiTechParkListApiEndpoint, userListApiEndpoint } from '../../utils/allurls';
import { ADMIN_USER } from '../../utils/constants';
import { BASE_HEADERS } from '../../utils/baseValues';

const options = [
  {
    id: 1,
    title: 'Land',
  },
  {
    id: 2,
    title: 'Space',
  },
];

export default function CreateProjectProfile() {
  const [selAreaType, setSelAreaType] = useState(options[0]);
  const [selPark, setSelPark] = useState(null);
  const [selUser, setSelUser] = useState(null);
  const [parkList, updateParkList] = useState([]);
  const [userList, updateUserList] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();
  const projectProfileReducer = useSelector((state) => state.projectProfileReducer);

  const initProjectProfile = {
    tenant_id: userType() === ADMIN_USER ? '' : userId(),
    park_id: '',
    land_or_space: 'land',
    investor_name: '',
    investor_type: '',
    allotment_date: '',
    space_area: 0,
    floor_number: null,
    land_area: 0,
    block_zone_no: null,
    plot_number: null,
    rent_amount: 0,
    due_rent: 0,
    due_electricity_bill: 0,
    employment: 0,
    investment_amount: 0,
  };

  const [projectProfileData, setProjectProfileData] = useState(initProjectProfile);
  const [allotment_date_picker, set_allotment_date_picker] = useState(new Date());

  const handleOnchange = (e, type) => {
    const fieldName = e.target.name;

    let fieldValue;
    if (type === 'number') {
      let temp = Number(e.target.value);
      fieldValue = temp || 0;
    } else {
      fieldValue = e.target.value;
    }
    setProjectProfileData({
      ...projectProfileData,
      [fieldName]: fieldValue,
    });

    console.log('projectProfileData : ', projectProfileData);
  };

  const resetPageData = () => {
    setProjectProfileData(initProjectProfile);
    setSelPark(null);
    setSelUser(null);
  };

  const submitData = (e) => {
    e.preventDefault();
    const status = checkForm(projectProfileData);
    if (status) {
      dispatch(createProjectProfile(projectProfileData));
      resetPageData();
    }
  };

  const getParkListData = async () => {
    const res = await getParkList();
    if (res) updateParkList(res);
  };

  const getUserListData = async () => {
    const res = await getUserList();
    if (res) updateUserList(res);
  };

  useEffect(() => {
    const copy = { ...projectProfileData };
    if (selPark) copy['park_id'] = selPark.id;
    setProjectProfileData(copy);
  }, [selPark]);

  useEffect(() => {
    const copy = { ...projectProfileData };
    if (selUser) copy['tenant_id'] = selUser.id;
    setProjectProfileData(copy);
  }, [selUser]);

  useEffect(() => {
    const copy = { ...projectProfileData };
    if (selAreaType) {
      copy['land_or_space'] = selAreaType.title?.toLowerCase();
    }
    setProjectProfileData(copy);
  }, [selAreaType]);

  useEffect(() => {
    getParkListData();
    getUserListData();
    setProjectProfileData((state) => ({ ...state, allotment_date: getDateOneFormatToAnother(allotment_date_picker, 'yyyy-MM-DD') }));
  }, []);

  return (
    <div className='pb-5'>
      {
        <div className='body'>
          <PageHeader
            HeaderText={`Create project profile`}
            Breadcrumb={[
              { name: 'Profile profile', navigate: '' },
              { name: 'Create', navigate: '' },
            ]}
          />

          <div className=''>
            <div className='row px-3'>
              {userType() === ADMIN_USER && (
                <div className='mb-3 col-md-6'>
                  <span>Investor Account</span>
                  <SelectFromOption
                    onClick={(op) => setSelUser(op)}
                    options={userList || []}
                    value={selUser || {}}
                    displayKey='name'
                    placeholder='Select Investor Account'
                    headerClass='header-class text-[13px]'
                  />
                </div>
              )}
              <div className='mb-3 col-md-6'>
                <span>Select HiTech Park</span>
                <SelectFromOption
                  onClick={(op) => setSelPark(op)}
                  options={parkList || []}
                  value={selPark || {}}
                  displayKey='project_name'
                  placeholder='Select Park'
                  headerClass='header-class text-[13px]'
                />
              </div>

              <div className='mb-3 col-md-6'>
                <span>Select Area Type</span>
                <SelectFromOption
                  onClick={(op) => setSelAreaType(op)}
                  options={options || []}
                  value={selAreaType}
                  displayKey='title'
                  headerClass='header-class text-[13px]'
                />
              </div>
              <div className='form-group  col-md-6'>
                <span>Investor name</span>
                <input
                  className='form-control'
                  placeholder=''
                  name='investor_name'
                  value={projectProfileData.investor_name}
                  onChange={(e) => handleOnchange(e)}
                />
              </div>
              <div className='form-group  col-md-6'>
                <span>Investor type</span>
                <input
                  className='form-control'
                  placeholder=''
                  name='investor_type'
                  value={projectProfileData.investor_type}
                  onChange={(e) => handleOnchange(e)}
                />
              </div>

              <div className='form-group  col-md-6'>
                <span>Allotment date</span>
                <DatePicker
                  dateFormat='yyyy-MM-dd'
                  name='allotment_date'
                  className='form-control'
                  selected={allotment_date_picker}
                  onChange={(date) => {
                    set_allotment_date_picker(date);
                    setProjectProfileData((state) => {
                      return {
                        ...state,
                        allotment_date: getDateOneFormatToAnother(date, 'yyyy-MM-DD'),
                      };
                    });
                  }}
                />
              </div>
              {selAreaType.title === 'Space' && (
                <>
                  <div className='form-group  col-md-6'>
                    <span>Space amount (square feet)</span>
                    <input
                      className='form-control'
                      placeholder=''
                      name='space_area'
                      value={projectProfileData.space_area}
                      onChange={(e) => handleOnchange(e, 'number')}
                    />
                  </div>
                  <div className='form-group  col-md-6'>
                    <span>Floor number</span>
                    <input
                      className='form-control'
                      placeholder=''
                      name='floor_number'
                      value={projectProfileData.floor_number}
                      onChange={(e) => handleOnchange(e, 'number')}
                    />
                  </div>
                </>
              )}
              {selAreaType.title === 'Land' && (
                <>
                  <div className='form-group  col-md-6'>
                    <span>Land area (square meter)</span>
                    <input
                      className='form-control'
                      placeholder=''
                      name='land_area'
                      value={projectProfileData.land_area}
                      onChange={(e) => handleOnchange(e, 'number')}
                    />
                  </div>
                  <div className='form-group  col-md-6'>
                    <span>Zone/block</span>
                    <input
                      className='form-control'
                      placeholder=''
                      name='block_zone_no'
                      value={projectProfileData.block_zone_no}
                      onChange={(e) => handleOnchange(e)}
                    />
                  </div>

                  <div className='form-group  col-md-6'>
                    <span>Plot number</span>
                    <input
                      className='form-control'
                      placeholder=''
                      name='plot_number'
                      value={projectProfileData.plot_number}
                      onChange={(e) => handleOnchange(e, 'number')}
                    />
                  </div>
                </>
              )}

              <div className='form-group  col-md-6'>
                <span>Rent amount (Tk)</span>
                <input
                  className='form-control'
                  placeholder=''
                  name='rent_amount'
                  value={projectProfileData.rent_amount}
                  onChange={(e) => handleOnchange(e, 'number')}
                />
              </div>
              <div className='form-group  col-md-6'>
                <span>Due rent (Tk)</span>
                <input
                  className='form-control'
                  placeholder=''
                  name='due_rent'
                  value={projectProfileData.due_rent}
                  onChange={(e) => handleOnchange(e, 'number')}
                />
              </div>

              <div className='form-group  col-md-6'>
                <span>Due electricity (Tk)</span>
                <input
                  className='form-control'
                  placeholder=''
                  name='due_electricity_bill'
                  value={projectProfileData.due_electricity_bill}
                  onChange={(e) => handleOnchange(e, 'number')}
                />
              </div>
              <div className='form-group  col-md-6'>
                <span>Number Of Job created</span>
                <input
                  className='form-control'
                  placeholder=''
                  name='employment'
                  value={projectProfileData.employment}
                  onChange={(e) => handleOnchange(e, 'number')}
                />
              </div>

              <div className='form-group  col-md-6'>
                <span>Investment amount (Tk)</span>
                <input
                  className='form-control'
                  placeholder=''
                  name='investment_amount'
                  value={projectProfileData.investment_amount}
                  onChange={(e) => handleOnchange(e, 'number')}
                />
              </div>

              <div className='form-group  col-md-6'>
                {projectProfileReducer.showMessage && !projectProfileReducer.isLoading && (
                  <Message data={projectProfileReducer.updatedMessageDialog} />
                )}

                {/* {initProjectProfile && resetPageData()} */}
              </div>
            </div>
            <div className='col-md-6'>
              <Button
                onClick={(e) => {
                  submitData(e);
                }}
                variant='primary'
              >
                {projectProfileReducer.loading && <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />}

                {projectProfileReducer.loading ? 'Submitting...' : 'Create new project'}
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                onClick={(e) => {
                  history.push('project-profile-dashboard');
                }}
                variant='secondary'
              >
                {'Back to project profile list'}
              </Button>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

// export default function CreateProjectProfile() {

//   const history = useHistory();
//   const dispatch = useDispatch()
//   const projectProfileReducer = useSelector(state => state.projectProfileReducer)

//   const initProjectProfile = {
//     tenant_id: userId(),
//     project_name: "",
//     project_code: "",
//     plot_number: "",
//     contact_name: "",
//     contact_phone: "",
//     contact_email: "",
//     land_allocation_date: "",
//     project_start_date: "",
//     project_completion_date: "",
//     expected_operation_date: "",
//     construction_area: 0,
//     number_of_floor: 0,
//     status: "pending",
//     site_name: "Kaliakoir Hi-tech Park",
//   }

//   const [projectProfileData, setProjectProfileData] = useState(initProjectProfile)
//   const [land_allocation_date_picker, set_land_allocation_date_picker] = useState(new Date());
//   const [project_start_date_picker, set_project_start_date_picker] = useState(new Date());
//   const [project_completion_date_picker, set_project_completion_date_picker] = useState(new Date());
//   const [expected_operation_date_picker, set_expected_operation_date_picker] = useState(new Date());

//   const handleOnchange = (e) => {
//     const fieldName = e.target.name
//     const fieldValue = e.target.value
//     setProjectProfileData({
//       ...projectProfileData,
//       [fieldName]: fieldValue
//     })

//     console.log("projectProfileData : ", projectProfileData)
//   }

//   const resetPageData = () => {
//     //setProjectProfileData(initProjectProfile)
//   }

//   const submitData = (e) => {
//     e.preventDefault();
//     dispatch(createProjectProfile(projectProfileData))

//   }

//   return (
//     <div> {
//       (<div className="body">
//         <PageHeader
//           HeaderText={`Create project profile`}
//           Breadcrumb={[
//             { name: "Profile profile", navigate: "" },
//             { name: "Create", navigate: "" },
//           ]}
//         />

//         <div className="row clearfix">
//           <div className="col-lg-6 col-md-12">
//             <div className="form-group  col-md-6">
//               <span>Project name*</span>
//               <input
//                 className="form-control"
//                 placeholder=""
//                 name="project_name"
//                 value={projectProfileData.name}
//                 type="text"
//                 onChange={(e) => handleOnchange(e)}
//               />
//             </div>
//             <div className="form-group  col-md-6">
//               <span>Project code</span>
//               <input
//                 className="form-control"
//                 placeholder=""
//                 name="project_code"
//                 value={projectProfileData.project_code}
//                 type="text"
//                 onChange={(e) => handleOnchange(e)}
//               />
//             </div>
//             <div className="form-group  col-md-6">
//               <span>Plot number</span>
//               <input
//                 className="form-control"
//                 placeholder=""
//                 name="plot_number"
//                 value={projectProfileData.plot_number}
//                 type="text"
//                 onChange={(e) => handleOnchange(e)}
//               />
//             </div>
//             <div className="form-group  col-md-6">
//               <span>Contact name</span>
//               <input
//                 className="form-control"
//                 placeholder=""
//                 name="contact_name"
//                 value={projectProfileData.contact_name}
//                 type="text"
//                 onChange={(e) => handleOnchange(e)}
//               />
//             </div>

//             <div className="form-group  col-md-6">
//               <span>Contact phone</span>
//               <input
//                 className="form-control"
//                 placeholder=""
//                 name="contact_phone"
//                 value={projectProfileData.contact_phone}
//                 type="text"
//                 onChange={(e) => handleOnchange(e)}
//               />
//             </div>

//             <div className="form-group  col-md-6">
//               <span>Contact email</span>
//               <input
//                 className="form-control"
//                 placeholder=""
//                 name="contact_email"
//                 value={projectProfileData.contact_email}
//                 type="text"
//                 onChange={(e) => handleOnchange(e)}
//               />
//             </div>
//             <div className="form-group  col-md-6">
//               <span>Land allocation date</span>
//               <DatePicker dateFormat="yyyy-MM-dd" name="land_allocation_date" className="form-control" selected={land_allocation_date_picker}
//                 onChange={(date) => {

//                   set_land_allocation_date_picker(date)
//                   setProjectProfileData((state) => {
//                     return {
//                       ...state,
//                       land_allocation_date: getDateOneFormatToAnother(date, "yyyy-MM-DD")
//                     }
//                   })
//                 }
//                 }
//               />

//             </div>
//             <div className="form-group  col-md-6">
//               <span>Project start date</span>
//               <DatePicker dateFormat="yyyy-MM-dd" name="project_start_date" className="form-control" selected={project_start_date_picker}
//                 onChange={(date) => {
//                   set_project_start_date_picker(date)
//                   setProjectProfileData((state) => {
//                     return {
//                       ...state,
//                       project_start_date: getDateOneFormatToAnother(date, "yyyy-MM-DD")
//                     }
//                   })
//                 }
//                 }
//               />
//             </div>
//             <div className="form-group  col-md-6">
//               <span>Project completion date</span>
//               <DatePicker dateFormat="yyyy-MM-dd" name="project_completion_date" className="form-control" selected={project_completion_date_picker}
//                 onChange={(date) => {
//                   set_project_completion_date_picker(date)
//                   setProjectProfileData((state) => {
//                     return {
//                       ...state,
//                       project_completion_date: getDateOneFormatToAnother(date, "yyyy-MM-DD")
//                     }
//                   })
//                 }
//                 }
//               />
//             </div>
//             <div className="form-group  col-md-6">
//               <span>Expected operation date</span>
//               <DatePicker dateFormat="yyyy-MM-dd" name="expected_operation_date" className="form-control" selected={expected_operation_date_picker}
//                 onChange={(date) => {
//                   set_expected_operation_date_picker(date)
//                   setProjectProfileData((state) => {
//                     return {
//                       ...state,
//                       expected_operation_date: getDateOneFormatToAnother(date, "yyyy-MM-DD")
//                     }
//                   })
//                 }
//                 }
//               />
//             </div>
//             <div className="form-group  col-md-6">
//               <span>Construction area(square foot)</span>
//               <input
//                 className="form-control"
//                 placeholder=""
//                 name="construction_area"
//                 value={projectProfileData.construction_area}
//                 type="text"
//                 onChange={(e) => handleOnchange(e)}
//               />
//             </div>
//             <div className="form-group  col-md-6">
//               <span>Number of floors</span>
//               <input
//                 className="form-control"
//                 placeholder=""
//                 name="number_of_floor"
//                 value={projectProfileData.number_of_floor}
//                 type="text"
//                 onChange={(e) => handleOnchange(e)}
//               />
//             </div>
//             <div className="form-group  col-md-6">
//               <span>Site name</span>
//               <input
//                 className="form-control"
//                 placeholder=""
//                 name="site_name"
//                 value={projectProfileData.site_name}
//                 type="text"
//                 onChange={(e) => handleOnchange(e)}
//               />
//             </div>

//             <div className="form-group  col-md-6">

//               {(projectProfileReducer.showMessage && !projectProfileReducer.isLoading) && <Message data={projectProfileReducer.updatedMessageDialog} />}

//               {initProjectProfile && resetPageData()}
//             </div>

//             <div>

//               <Button onClick={(e) => { submitData(e) }} variant="primary">
//                 {
//                   projectProfileReducer.loading && <Spinner
//                     as="span"
//                     animation="grow"
//                     size="sm"
//                     role="status"
//                     aria-hidden="true"
//                   />
//                 }

//                 {projectProfileReducer.loading ? "Submitting..." : "Create new project"}
//               </Button>

//               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

//               <Button onClick={(e) => { history.push("project-profile-dashboard") }} variant="secondary">
//                 {"Back to project profile list"}
//               </Button>
//               <br></br>
//               <br></br>
//               <br></br>

//             </div>
//           </div>
//         </div>
//       </div>)}

//     </div>
//   )
// }

export async function getParkList() {
  const reqUrl = hiTechParkListApiEndpoint;
  const res = await axios.get(reqUrl);
  if (res.status === 200 || true) return res.data.data;
  return false;
}

export async function getUserList() {
  const reqUrl = userListApiEndpoint;
  const headers = BASE_HEADERS();
  const res = await axios.get(reqUrl, { headers });
  if (res.status === 200 || true) return res.data.data;
  return false;
}

export function checkForm(object) {
  let status = true;
  for (const key in object) {
    if (['', undefined].includes(object[key])) {
      status = false;
      if (key === 'tenant_id') alert('Please select Investor Account');
      else if (key === 'park_id') alert('Please select Park');
      else {
        let tep = key.split('_').join(' ');
        alert(`Please Select ${tep.at(0).toUpperCase() + tep.slice(1)}`);
      }
      break;
    }
  }
  return status;
}
