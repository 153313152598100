import { getToken } from './TokenManage';

//localhost
// export const BASE_URL = "http://localhost:5050";
// export const IMAGE_BASE_URL = "http://localhost:5050/static/";

//dev env
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

//bhtpa server live url
// export const BASE_URL = 'https://digidash.bhtpa.gov.bd/api';
// export const IMAGE_BASE_URL = `https://digidash.bhtpa.gov.bd/static/`;

export const BASE_HEADERS = () => {
  const token = getToken();
  if (token) {
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Content-Security-Policy': 'upgrade-insecure-requests',
      Authorization: 'Bearer ' + token,
    };
  }

  return {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Content-Security-Policy': 'upgrade-insecure-requests',
  };
};

export const BASE_HEADERS_FILE_TYPE = () => {
  const token = getToken();
  if (token) {
    return {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      'Content-Security-Policy': 'upgrade-insecure-requests',
      Authorization: 'Bearer ' + token,
    };
  }

  return {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
    'Content-Security-Policy': 'upgrade-insecure-requests',
  };
};
