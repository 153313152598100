import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { TENANT_USER, ADMIN_USER } from '../../utils/constants';
import Button from 'react-bootstrap/Button';
import { useFetch, useFetchDataFrom } from '../../customHooks/fetchData';
import { getProjectProfileListURL, projectProfileUpdate, ProjectProfileUpdate } from '../../utils/allurls';
import { userId, userType } from '../../utils/userUtils';
import { useSelector, useDispatch } from 'react-redux';
import { getProjectUpdateJsonBody } from '../../utils/GeneralUtils';
import { updateProjectProfileStatus, deleteProjectProfile } from '../../actions/projectProfileAction';
import swal from 'sweetalert';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { PaginatedItems } from '../../components/Paginate/Paginate';

export default function InvestorProfileList(props) {
  const [show, setShow] = useState(false);
  const [profileInfo, setProfileInfo] = useState({});
  const handleClose = () => setShow(false);

  const handleShow = (prevInfo) => {
    setProfileInfo(prevInfo);
    setShow(true);
  };

  const history = useHistory();
  const dispatch = useDispatch();
  const projectProfileReducer = useSelector((state) => state.projectProfileReducer);

  const getUrl = (user_type, query) => {
    var requestURL;
    if (user_type === TENANT_USER) {
      requestURL = getProjectProfileListURL('', userId(), query.page, query.per_page);
    } else if (user_type === ADMIN_USER) {
      requestURL = getProjectProfileListURL('', '', query.page, query.per_page);
    }
    return requestURL;
  };

  const [queryObj, updateQueryObject] = useState({ page: 0, per_page: 20 });
  //   const { loading, data} = useFetch(getAllUsersURL(user_type));
  // const { loading, data, success } = useFetch(requestURL);

  const { loading, data, success, disPatcher } = useFetchDataFrom(getUrl(props.user_type, queryObj));

  useEffect(() => {
    disPatcher(getUrl(props.user_type, queryObj));
  }, [queryObj, props.user_type, disPatcher]);

  const projectUpdateSuccess = (success) => {
    if (success) {
      swal('Project profile update status', 'Updated succesfully', 'success').then(function () {
        window.location.reload(false);
      });
    }
  };

  const projectDeleteSuccess = (success) => {
    if (success) {
      swal('Project profile delete status', 'Deleted succesfully', 'success').then(function () {
        window.location.reload(false);
      });
    }
  };

  const handleOnchange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    setProfileInfo({
      ...profileInfo,
      [fieldName]: fieldValue,
    });

    console.log('projectProfileData : ', profileInfo);
  };

  const deleteProject = (projectId) => {
    dispatch(deleteProjectProfile(projectId));
  };

  return (
    <>
      <div className='col-lg-12'>
        <div className='card product_item_list product-order-list'>
          <div className='header align-space-between-side'>
            <h2>Investor profile List</h2>
            {(userType() === TENANT_USER || userType() === ADMIN_USER) && (
              <Button
                onClick={(e) => {
                  history.push('create-project-profile');
                }}
                variant='primary'
              >
                {'Create investor profile'}
              </Button>
            )}
          </div>
          <div className='body'>
            <div className='table-responsive'>
              <table className='table table-hover m-b-0'>
                <thead className='thead-dark'>
                  <tr>
                    <th>Investor Name</th>
                    <th data-breakpoints='sm xs'>Type</th>
                    <th>Details</th>
                    <th data-breakpoints='xs'>Allotment Date</th>
                    <th data-breakpoints='xs'>Rent (Tk)</th>
                    <th data-breakpoints='xs'>Due Rent (Tk)</th>
                    <th data-breakpoints='xs'>Due Electricity Bill (Tk)</th>
                    <th data-breakpoints='xs'>Employment Created</th>
                    <th data-breakpoints='xs'>Investment Amount (Tk)</th>

                    <th data-breakpoints='xs md'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {success &&
                    data.data.map((project, index) => (
                      <tr key={index}>
                        <td>{project.investor_name || '-'}</td>
                        <td>{project.land_or_space || '-'}</td>
                        <td>
                          {project.land_or_space === 'space' ? (
                            <div>
                              Space area: <span>{project.space_area || '-'}</span> <br />
                              Floor number: <span>{project.floor_number || '-'}</span>
                            </div>
                          ) : (
                            <div>
                              Land area: <span>{project.land_area || '-'}</span>
                              Plot no: <span>{project.plot_number || '-'}</span>
                              Block no: <span>{project.block_zone_no || '-'}</span>
                            </div>
                          )}
                        </td>
                        <td>{project.allotment_date || '-'}</td>
                        <td>{project.rent_amount || '-'}</td>
                        <td>{project.due_rent || '-'}</td>
                        <td>{project.due_electricity_bill || '-'}</td>
                        <td>{project.employment || '-'}</td>
                        <td>{project.investment_amount || '-'}</td>

                        <td>
                          <div className='flex'>
                            <Button onClick={() => handleShow(project)}>Edit</Button>
                            {props.user_type === ADMIN_USER && (
                              <a className='ml-2 btn btn-outline-secondary'>
                                <i
                                  className='icon-trash'
                                  onClick={() => {
                                    deleteProject(project.id);
                                  }}
                                ></i>
                              </a>
                            )}
                          </div>
                        </td>

                        {projectUpdateSuccess(projectProfileReducer.updateSuccess)}
                        {projectDeleteSuccess(projectProfileReducer.deleteSuccess)}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='my-4'>
            <PaginatedItems
              itemsPerPage={queryObj.per_page}
              onClick={(parm) => updateQueryObject({ ...queryObj, page: parm })}
              totalItems={data.pagination?.total}
            />
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='body'>
            <div className='mb-5'>
              <div>
                Investor name: <span>{profileInfo.investor_name || ''}</span>
              </div>
              <div>
                Type: <span>{profileInfo.land_or_space || '-'}</span>
              </div>
              {profileInfo.land_or_space === 'space' ? (
                <div>
                  Space area: <span>{profileInfo.space_area || '-'}</span>
                  <br />
                  Floor number: <span>{profileInfo.floor_number || '-'}</span>
                </div>
              ) : (
                <div>
                  Land area: <span>{profileInfo.land_area || '-'}</span>
                  Plot no: <span>{profileInfo.plot_number || '-'}</span>
                  Block no: <span>{profileInfo.block_zone_no || '-'}</span>
                </div>
              )}
            </div>
            <div className='row px-3'>
              <div className='form-group  col-md-6'>
                <span>Rent amount (Tk)</span>
                <input
                  className='form-control'
                  placeholder=''
                  name='rent_amount'
                  value={profileInfo.rent_amount}
                  type='text'
                  onChange={(e) => handleOnchange(e)}
                />
              </div>
              <div className='form-group  col-md-6'>
                <span>Due rent (Tk)</span>
                <input
                  className='form-control'
                  placeholder=''
                  name='due_rent'
                  value={profileInfo.due_rent || ''}
                  type='text'
                  onChange={(e) => handleOnchange(e)}
                />
              </div>

              <div className='form-group  col-md-6'>
                <span>Due electricity bill (Tk)</span>
                <input
                  className='form-control'
                  placeholder=''
                  name='due_electricity_bill'
                  value={profileInfo.due_electricity_bill}
                  type='text'
                  onChange={(e) => handleOnchange(e)}
                />
              </div>
              <div className='form-group  col-md-6'>
                <span>Employment Created</span>
                <input
                  className='form-control'
                  placeholder=''
                  name='employment'
                  value={profileInfo.employment || ''}
                  type='text'
                  onChange={(e) => handleOnchange(e)}
                />
              </div>

              <div className='form-group  col-md-6'>
                <span>Investment amount (Tk)</span>
                <input
                  className='form-control'
                  placeholder=''
                  name='investment_amount'
                  value={profileInfo.investment_amount || ''}
                  type='text'
                  onChange={(e) => handleOnchange(e)}
                />
              </div>
            </div>
          </div>
          <Button onClick={() => handleClickOnUpdate(profileInfo)}>Update</Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export async function handleClickOnUpdate(postData) {
  const reqUrl = projectProfileUpdate;
  const res = await axios.post(reqUrl, postData);
  if (res.status === 200) {
    window.location.reload();
  } else alert('Some thing is wrong');
}
