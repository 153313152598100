import React from "react";
import PageHeader from "../../components/PageHeader";
import ComparisonGraph from "./ComparisonGraph";
import InvestorProfileList from "./InvestorProfileList";
import {ADMIN_USER} from "../../utils/constants"
import ProjectProfileSummary from "../TenantDashboard/ProjectProfileSummary";


export default function ProjectsDashboard() {

  return (
    <div className="dashboard-container">
      <PageHeader
        HeaderText="Projects dashboard"
        Breadcrumb={[{ name: "projects" }]}
      />
      <div className={`device-main-container`}>
        <div className={`dashboard-container-summary`}>
        <ProjectProfileSummary user_type = {ADMIN_USER} />
        </div>
        
        { false && <ComparisonGraph />}
        <InvestorProfileList user_type={ADMIN_USER} />
        
      </div>
    </div>
  )
}