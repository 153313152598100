import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader";
import path from 'path'
import { useFetch } from "../../customHooks/fetchData"
import { getDashboardDataURL } from "../../utils/allurls"
import ProjectScheduleList from "../../screens/Dashbord/ProjectScheduleList"
import { TENANT_USER } from "../../utils/constants"
import ScheduleStatsSummary from "./ScheduleStatsSummary";

export default function TenantUserProjectScheduleDashboard() {

  const dispatch = useDispatch();

  console.log(path.resolve(__dirname, './__test__'));
  const dashboardData = useFetch(getDashboardDataURL("project-profile-tenant"))

 


  return (
    <div className="dashboard-container">
      <PageHeader
        HeaderText="Schedule dashboard"
        Breadcrumb={[{ name: "project-schedule" }]}


      />
      <div className={`dashboard-container-summary`}>
        <ScheduleStatsSummary user_type={TENANT_USER} />
      </div>
      
      <div className={`dashboard-container-summary`}>
        <ProjectScheduleList user_type={TENANT_USER} />
      </div>
    </div>
  )
}


