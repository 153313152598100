import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { TENANT_USER, ADMIN_USER, PD_USER } from '../../utils/constants';
import { useFetch, useFetchDataFrom } from '../../customHooks/fetchData';
import { getProjectImplementationProgressListURL, getAllUsersURL, projectImplementationUpdateShowDashboardURL } from '../../utils/allurls';
import { userId } from '../../utils/userUtils';
import { useSelector, useDispatch } from 'react-redux';
import { updateProjectImplToDirector, deleteProjectImplProgress, updateProjectType } from '../../actions/projectImplNameAction';
import swal from 'sweetalert';
import { dateInMonthYearFormat } from '../../utils/DateUtils';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { PaginatedItems } from '../../components/Paginate/Paginate';

export default function ProjectImplNameList(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const projectImplNameReducer = useSelector((state) => state.projectImplNameReducer);

  const getUrl = (user_type, query) => {
    var requestURL;
    if (user_type === TENANT_USER || user_type === PD_USER) {
      requestURL = getProjectImplementationProgressListURL(userId(), 1, query.page, query.per_page);
    } else if (user_type === ADMIN_USER) {
      requestURL = getProjectImplementationProgressListURL('', 1, query.page, query.per_page);
    }
    return requestURL;
  };

  const [show, setShow] = useState(false);
  const [projectInfo, setProjectInfo] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [queryObj, updateQueryObject] = useState({ page: 0, per_page: 20 });
  //   const { loading, data} = useFetch(getAllUsersURL(user_type));
  // const { loading, data, success } = useFetch(requestURL);

  const { loading, data, success, disPatcher } = useFetchDataFrom(getUrl(props.user_type, queryObj));

  useEffect(() => {
    disPatcher(getUrl(props.user_type, queryObj));
  }, [queryObj, props.user_type, disPatcher]);

  const userList = useFetch(getAllUsersURL(PD_USER));
  const deleteProjectImplProgressRequest = (projectId) => {
    dispatch(deleteProjectImplProgress(projectId));
  };

  const updateProjectTypeRequest = (projectId, type) => {
    dispatch(updateProjectType({ id: projectId, type }));
  };

  const projectUpdateSuccess = (success) => {
    if (success) {
      swal('Project name update status', 'Updated succesfully', 'success').then(function () {
        window.location.reload(false);
        handleClose();
      });
    }
  };

  const projectTypeSuccess = (success) => {
    if (success) {
      swal('Project type status', 'Updated successfully', 'success').then(function () {
        window.location.reload(false);
        handleClose();
      });
    }
  };

  const projectDeleteSuccess = (success) => {
    if (success) {
      swal('Project name delete status', 'Project name deleted successfully', 'success').then(function () {
        window.location.reload(false);
      });
    }
  };

  return (
    <>
      <div className='col-lg-12'>
        <div className='card product_item_list product-order-list'>
          <div className='header align-space-between-side'>
            <h2>Project name list</h2>
            {props.user_type === ADMIN_USER && (
              <Button
                onClick={(e) => {
                  history.push('create-project-implementation-name');
                }}
                variant='primary'
              >
                {'Create project name'}
              </Button>
            )}
          </div>
          <div className='body'>
            <div className='table-responsive'>
              <table className='table table-hover m-b-0'>
                <thead className='thead-dark'>
                  <tr>
                    <th data-breakpoints='sm xs'>Sl.</th>
                    <th>Project name</th>
                    <th>Start term date</th>
                    <th>End term date</th>
                    <th data-breakpoints='sm xs md'>Type</th>
                    <th data-breakpoints='xs'>Project focal point</th>
                    <th data-breakpoints='sm xs md'>Assign</th>

                    <th data-breakpoints='sm xs md'>Action</th>
                    <th data-breakpoints='sm xs md'>Show On Dashboard</th>
                  </tr>
                </thead>
                <tbody>
                  {loading && 'Loading...'}
                  {success &&
                    data.data.map((project, index) => (
                      <tr key={index}>
                        <td>
                          <div className='request-top' style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                            <p>{project.id}</p>
                          </div>
                        </td>
                        <td>
                          <div className='request-top' style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                            <p>{project.project_name}</p>
                          </div>
                        </td>
                        <td>{dateInMonthYearFormat(project.start_date)}</td>
                        <td>{dateInMonthYearFormat(project.end_date)}</td>
                        <td>{project.type === 1 ? <div>Running project</div> : <div>Operational project</div>}</td>

                        <td>
                          {project.user_id && (
                            <div>
                              <div>{project.name}</div>
                              <span>{project.description}</span>
                            </div>
                          )}
                        </td>

                        {props.user_type === ADMIN_USER && (
                          <td>
                            {
                              <Button
                                onClick={() => {
                                  setProjectInfo({ id: project.id, project_name: project.project_name });

                                  handleShow();

                                  // dispatch(updateProjectScheduleStatus(getProjectUpdateJsonBody(project.id, "approved")))
                                }}
                              >
                                {'Select focal point'}
                              </Button>
                            }

                            {projectUpdateSuccess(projectImplNameReducer.updateSuccess)}
                          </td>
                        )}

                        {props.user_type === ADMIN_USER && (
                          <td>
                            <div className='d-flex align-items-center'>
                              <a className='btn btn-outline-secondary'>
                                <i
                                  className='icon-trash'
                                  onClick={() => {
                                    swal({
                                      title: 'Are you sure?',
                                      text: 'Once deleted, you will not be able to recover this project name!',
                                      icon: 'warning',
                                      buttons: true,
                                      dangerMode: true,
                                    }).then((willDelete) => {
                                      if (willDelete) {
                                        deleteProjectImplProgressRequest(project.id);
                                      }
                                    });
                                  }}
                                ></i>
                              </a>
                              &nbsp;&nbsp;
                              {project.type === 1 ? (
                                <Button
                                  style={{ width: '90px' }}
                                  onClick={(e) => {
                                    swal({
                                      title: 'Are you sure?',
                                      text: 'You want to make this project as completed',
                                      icon: 'warning',
                                      buttons: true,
                                      dangerMode: false,
                                    }).then((willUpdate) => {
                                      if (willUpdate) {
                                        updateProjectTypeRequest(project.id, 2);
                                      }
                                    });
                                  }}
                                  variant='secondary'
                                >
                                  {'Complete'}
                                </Button>
                              ) : (
                                <Button
                                  style={{ width: '90px' }}
                                  onClick={(e) => {
                                    swal({
                                      title: 'Are you sure?',
                                      text: 'You want to revert back to the Running project',
                                      icon: 'warning',
                                      buttons: true,
                                      dangerMode: false,
                                    }).then((willUpdate) => {
                                      if (willUpdate) {
                                        updateProjectTypeRequest(project.id, 1);
                                      }
                                    });
                                  }}
                                  variant='secondary'
                                >
                                  {'Undo'}
                                </Button>
                              )}
                            </div>
                          </td>
                        )}
                        {props.user_type === ADMIN_USER && (
                          <td>
                            <input
                              onChange={() => handleClickOnCheckbox({ id: project.id, show_dashboard: project.show_dashboard === 0 ? 1 : 0 })}
                              style={{ width: '24px', height: '24px' }}
                              type='checkbox'
                              checked={project.show_dashboard}
                            />
                          </td>
                        )}

                        {projectDeleteSuccess(projectImplNameReducer.deleteSuccess)}
                        {projectTypeSuccess(projectImplNameReducer.typeSuccess)}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='my-4'>
            <PaginatedItems
              itemsPerPage={queryObj.per_page}
              onClick={(parm) => updateQueryObject({ ...queryObj, page: parm })}
              totalItems={data.pagination?.total}
            />
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Select focal point for <b>{projectInfo.project_name}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='body'>
            <div className='table-responsive'>
              <table className='table table-hover m-b-0'>
                <thead className='thead-dark'>
                  <tr>
                    <th data-breakpoints='sm xs'>Sl.</th>
                    <th>Project focal point name</th>

                    <th data-breakpoints='sm xs md'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading && 'Loading...'}
                  {userList.success &&
                    userList.data.data.map((userObj, index) => (
                      <tr key={index}>
                        <td>
                          <div className='request-top' style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                            <p>{index + 1}</p>
                          </div>
                        </td>
                        <td>
                          <div className='request-top' style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                            <div style={{ fontSize: '18px' }}>{userObj.name}</div>
                            <div>{userObj.description}</div>
                          </div>
                        </td>

                        {props.user_type === ADMIN_USER && (
                          <td>
                            {
                              <Button
                                onClick={() => {
                                  dispatch(updateProjectImplToDirector({ id: projectInfo.id, user_id: userObj.id }));
                                  handleClose();
                                }}
                              >
                                {'Select as focal point'}
                              </Button>
                            }

                            {projectUpdateSuccess(projectImplNameReducer.updateSuccess)}
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export async function handleClickOnCheckbox(postData) {
  const reqUrl = projectImplementationUpdateShowDashboardURL;
  const res = await axios.post(reqUrl, postData);
  if (res.status === 200) {
    window.location.reload();
  } else alert('Some thing is wrong');
}
