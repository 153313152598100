import React from "react";
import PageHeader from "../../components/PageHeader";
import {  TENANT_USER } from "../../utils/constants";
import ProjectProfileSummary from "./ProjectProfileSummary";
import SupportStatsSummary from "./SupportStatsSummary";
import ScheduleStatsSummary from "./ScheduleStatsSummary";
import ExtensionStatsSummary from "./ExtensionStatsSummary";
import RentStatsSummary from "./RentStatsSummary";


export default function TenantUserDashbord(): JSX.Element {

  return (
    <div className="dashboard-container">
      
      <PageHeader
        HeaderText="Dashboard"
        Breadcrumb={[{ name: "Dashboard" }]}
      />
      <div className={`dashboard-container-summary`}>
        <ProjectProfileSummary user_type = {TENANT_USER} />
        <ScheduleStatsSummary user_type={TENANT_USER} />
        <ExtensionStatsSummary user_type={TENANT_USER} />
        <SupportStatsSummary user_type = {TENANT_USER} />
        <RentStatsSummary user_type = {TENANT_USER} />
        
       {/* {dashboardData.success && <PieChart data= {getDashboardPiechartData(dashboardData.data.data)} /> } */}
      </div>
      <div className={`dashboard-container-summary`}>
       
      </div>
    </div>
  )
}


